@import './variables.scss';

$font-family-base: 'Nunito Sans';

:root {
  --body-background: #f8f8f8;
  --primary-color: #0047cf;
  --primary-btn-text-color: #ffffff;
  --primary-text-color: $primary-text-color;
  --secondary-color: #dae2ff;
  --secondary-btn-text-color: #002c71;
  --table-primary-background: #eff0ff;
  --table-secondary-background: #dfe6f8;
  --accent-color-1: #417e71;
  --accent-color-2: #72dd70;
  --accent-color-3: #ff5449;
  --accent-color-4: #c6c6d0;
  --accent-color-5: #fbc02d;
  --accent-color-6: #0047cf;
  --inactive-color: #6c757d;
  --main-bg-color: #0081cc;
}
html,
body {
  height: 100vh;
  margin: 0;
  font-size: 14px;
  font-family: $font-family-base;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  padding: 1rem;
  overflow: hidden;
  // background-color: #eef1fb;
}

.text-black {
  color: $primary-text-color;
}

.p-checkbox {
  width: 1.5rem;
  height: 1.5rem;

  .p-checkbox-box {
    border-radius: 0;
    border-color: $gray;
    width: 1.5rem;
    height: 1.5rem;

    &.p-highlight {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .p-icon {
      width: 1rem;
      height: 1rem;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $primary-color;

        &.p-highlight {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $primary-btn-text-color;
        }
      }

      &.p-focus {
        box-shadow: none;
        border-color: $primary-color;
      }
    }
  }
}

.p-inputtext:enabled {
  &:focus {
    box-shadow: none;
    border-color: $primary-color;
  }

  &:hover {
    box-shadow: none;
    border-color: $primary-color;
  }
}

.p-button {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $primary-btn-text-color;

  &:enabled:hover {
    background-color: $secondary-color;
    color: $secondary-btn-text-color;
    border-color: $secondary-color;
  }

  &.p-button-outlined {
    color: $primary-color;
    background-color: $white;
    &:enabled:hover {
      color: $primary-color;
    }
  }

  &.p-button-text {
    color: $primary-color;
    background: transparent;
    border: none;
    &:enabled:hover {
      background-color: $secondary-color;
      color: $secondary-btn-text-color;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.secondary-button {
  &.p-button {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $secondary-btn-text-color;
    min-width: 9rem;
    &:enabled:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $primary-btn-text-color;
    }
  }
}

.action-icon {
  &:hover {
    cursor: pointer;
  }
}

.warning-icon {
  &:hover {
    cursor: default;
  }
  position: relative;
  font-size: 1.5rem;
}

.self-overlap-icon {
  color: $accent-color-3;
}

.tree-cover-loss-icon {
  color: #fbc02d;
}

.wdpa-icon {
  color: #2da63d;
}

.chevron-icon {
  color: $gray;
  font-weight: 700;
}

.edit-icon {
  color: #0047cf;
  font-size: 1.5rem;
}

.delete-icon {
  color: $accent-color-3;
  font-size: 1.5rem;
}

.header {
  font-size: 1.125rem;
  font-weight: 700;
}

.p-card {
  color: $primary-text-color;
  .p-card-body {
    padding: 0;
  }
  .p-card-content {
    padding: 1.25rem;
  }
}

.p-dialog {
  .p-dialog-header {
    padding-bottom: 0;
    .p-dialog-header-icon:focus {
      box-shadow: none;
    }
  }
}

.alert-confirm-modal {
  .p-dialog-mask {
    .p-dialog {
      .p-dialog-content {
        background: $white !important;
        padding: 1rem 1.5rem;

        .p-confirm-dialog-message {
          margin-left: 0;
        }
      }

      .p-dialog-footer {
        padding-top: 1rem;
        border-top: 1px solid $light-gray;
      }

      .p-confirm-dialog-reject {
        background-color: $white;
        border: 1px solid $primary-color;
        color: $primary-color;
      }
    }
  }
}

.p-column-filter-overlay {
  width: 20.5rem;
}

.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0;
  }
  &::after,
  &::before {
    border: none;
    left: 0;
  }
}

.p-datatable {
  .p-sortable-column.p-highlight {
    .p-sortable-column-icon {
      color: $primary-color;
    }
    color: $primary-color;
  }
  & > .p-datatable-wrapper {
    overflow: auto;
    max-height: 42rem;
  }
  &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: $table-secondary-background;
  }

  .p-datatable-header {
    padding: 0 0 0 0.25rem;

    background-color: $table-secondary-background;
  }
  .p-sortable-column .p-icon-wrapper {
    display: inline-flex !important;
  }

  .p-datatable-thead > tr > th {
    background-color: $table-secondary-background;
  }

  .p-datatable-tbody > tr.p-highlight {
    background-color: $table-secondary-background;
    color: $secondary-btn-text-color;
  }

  .p-datatable-tbody > tr > td {
    word-break: break-word;
  }
}

.hidden {
  display: none;
}

a:visited {
  text-decoration: none;
  color: $primary-color;
}

.p-toast {
  width: 40rem !important;
  position: absolute;
  top: 63vh;
  left: 35vw;

  .p-toast-message {
    &.p-toast-message-success {
      background: #c8f6ca;
      border: none;
      color: #005312;

      .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        color: #005312;
      }
    }
    .p-toast-message-content {
      padding: 0.5rem;
    }
  }
}

.p-progressbar .p-progressbar-value {
  background: $primary-color;
}

// for close button - refactor
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #005312;
}

.expand-span {
  font-size: 0.875rem;
  color: #0047cf;
  &:hover {
    cursor: pointer;
  }
}

.selected-si-length {
  color: $gray;
  font-size: 0.875;
}

.p-dialog {
  .p-dialog-header .p-dialog-title {
    font-size: 1.313rem;
    color: black;
  }
  .p-dialog-content {
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}

.p-paginator {
  justify-content: end !important;

  .p-paginator-pages .p-paginator-page.p-highlight {
    background: $table-secondary-background;
    border-color: $table-secondary-background;
    color: $secondary-btn-text-color;
  }
}

.p-inputtext {
  border-color: $gray;
}

.modal-table {
  .p-datatable {
    & > .p-datatable-wrapper {
      overflow: auto;
    }
  }

  .p-overlay {
    top: -135px !important;
  }
}

.modal-action-section {
  border-top: 2px solid $accent-color-4;
}

.clickable-icon {
  &:hover {
    cursor: pointer;
  }
}

.tab-content-header {
  font-size: 1.125rem;
  font-weight: 700;
}

.col-8-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 70%;
}

.col-4-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 30%;
}

.ml-5-2 {
  margin-left: 2.15rem;
}

.mt-3-2 {
  margin-top: 1.25rem;
}

.w-50 {
  width: 50%;
}

.checkbox-cell {
  width: 1rem;
  padding-left: 0.6rem !important;
  padding-right: 0 !important;
}

.p-calendar {
  width: 100%;
}

.p-link:focus {
  box-shadow: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $secondary-btn-text-color;
  background-color: $secondary-color;
}

.p-dropdown:not(.p-disabled) {
  &.p-focus {
    box-shadow: none;
    border-color: $primary-color;
  }
  &:hover {
    border-color: $primary-color;
  }
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  background-color: $table-secondary-background;
  color: $secondary-btn-text-color;
}

.p-datepicker table td > span.p-highlight {
  color: $secondary-btn-text-color;
  background-color: $table-secondary-background;
}

td {
  a {
    text-decoration: underline;
    color: $primary-color;
    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1439px) {
  html,
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  html,
  body {
    font-size: 10px;
  }
}
