$body-background: var(--body-background);
$primary-color: var(--primary-color);
$primary-btn-text-color: var(--primary-btn-text-color);
$primary-text-color: var(--primary-text-color);
$secondary-color: var(--secondary-color);
$secondary-btn-text-color: var(--secondary-btn-text-color);
$table-secondary-background: var(--table-secondary-background);
$accent-color-1: var(--accent-color-1);
$accent-color-2: var(--accent-color-2);
$accent-color-3: var(--accent-color-3);
$accent-color-4: var(--accent-color-4);
$accent-color-5: var(--accent-color-5);
$accent-color-6: var(--accent-color-6);
$white: #fff;
$gray: #5d5e67;
$light-gray: #dee2e6;
$black: #000;
